import React, { Component, useEffect, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import useFetch from "react-fetch-hook"
import { createAuthHeader } from "src/helpers/logged_in_user"

const MailingTrigger = ({ data, header, mailHandler, mailUrl, t }: any) => {
    const [sendEmailFlag, setSendEmailFlag] = useState<any>(0)

    const authHeaders = createAuthHeader("sendMailReports")

    const csvData = {
        header: header,
        data: data,
    }

    const {
        data: emailStatusResult,
        isLoading: emailStatusLoading,
        error: emailStatusError,
    } = useFetch<any>(`${mailUrl}`, {
        method: "POST",
        body: JSON.stringify(csvData),
        depends: [sendEmailFlag],
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        ...authHeaders,
    })

    useEffect(() => {
        mailHandler(emailStatusResult, emailStatusLoading, emailStatusError)
        if (emailStatusResult?.status == "sent" || emailStatusError) {
            setSendEmailFlag(0)
        }
    }, [emailStatusResult, emailStatusLoading, emailStatusError])

    return (
        <>
            <button
                onClick={() => {
                    console.log(`Triggered status mailing`)
                    setSendEmailFlag(1)
                }}
                type="button"
                className="btn btn-primary btn-rounded waves-effect waves-light"
            >
                {t("Email report")}
            </button>
        </>
    )
}

export default withTranslation()(MailingTrigger)
