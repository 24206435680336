import React, { useState } from "react"
import { Collapse } from "reactstrap"
import useFetch from "react-fetch-hook"
import { Spinner } from "react-awesome-spinners"
import classnames from "classnames"
import { createAuthHeader } from "../../helpers/logged_in_user"
import { blobname } from "."

const Blob = ({ blobContent }: any) => {
    const contentDisply = (blobContent: any) => {
        return JSON.stringify(
            !!blobContent ? blobContent.DecodedBody ?? "No data" : ""
        )
    }
    return (
        <>
            <strong className="text-dark">{contentDisply(blobContent)}</strong>
            <br />
        </>
    )
}

const BlobData = ({ name, opened }: any) => {
    const blobNameParam = encodeURIComponent(name)

    const {
        isLoading,
        data: blobContent,
        error,
    } = useFetch<any>(`/api/blob?name=${blobNameParam}`, {
        depends: [opened],
        ...createAuthHeader("admin"),
    })

    if (error) return <div>Error loading data from: {blobNameParam}</div>

    if (isLoading) return <Spinner />

    return (
        <>
            {Array.isArray(blobContent) ? (
                blobContent.map((oneBlob: any, index: number) => (
                    <Blob key={index} blobContent={oneBlob} />
                ))
            ) : (
                <Blob blobContent={blobContent} />
            )}
        </>
    )
}

export const BlobContent = ({ name }: blobname) => {
    const [opened, setOpened] = useState(null)

    return (
        <>
            <h2 className="accordion-header" id="headingOne">
                <button
                    className={classnames("accordion-button", "fw-medium", {
                        collapsed: !opened,
                    })}
                    type="button"
                    onClick={() => {
                        setOpened(!opened)
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {name}
                </button>
            </h2>
            <Collapse isOpen={opened} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-muted">
                        {opened ? (
                            <BlobData name={name} opened={opened} />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    )
}
