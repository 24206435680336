import React from "react"
import { Container } from "reactstrap"

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import ChangeLogContent from "../ChangeLogContent"

const ChangeLogConf = (props: any) => {
    const subTitle = props.t("Pending changes")
    document.title = `${props.t("Pending changes")} | LILA Controller admin`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Configuration")}
                />
                <ChangeLogContent
                    t={props.t}
                    type="config"
                    title="List of pending configuration changes"
                    apiUrl="/api/pending-conf"
                />
            </Container>
        </div>
    )
}

export default withTranslation()(ChangeLogConf)
