import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle, Row, Spinner } from "reactstrap"
import useFetch from "react-fetch-hook"
import ErrorHttp from "../ErrorHttp"
import { createAuthHeader } from "../../helpers/logged_in_user"
import { haspermAdmin, checkPermissions } from "../../helpers/permission_check"

//i18n
import { withTranslation } from "react-i18next"

import Blob from "./Blob"

const ChangeLogContent = (props: any) => {
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const [hasPermissions, setHasPermissions] = useState(false)

    const abortControllerRef = useRef<AbortController>()

    abortControllerRef.current = new AbortController()

    if (!hasPermissions) {
        checkPermissions(haspermAdmin, setHasPermissions)
    }

    const {
        isLoading,
        data: blobsList,
        error,
    } = useFetch<any>(`${props.apiUrl}?refreshKey=${refreshKey}`, {
        signal: abortControllerRef.current.signal,
        ...createAuthHeader("admin"),
    })

    const reloadParentHandler = () => {
        setRefreshKey((prevKey) => prevKey + 1)
    }

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }
    }, [])

    if (isLoading) return <Spinner />

    if (!hasPermissions) {
        return <ErrorHttp code="403" permission="admin" />
    }

    if (error) return <ErrorHttp code="500" />

    return (
        <>
            <Row>
                <Card>
                    <CardHeader>
                        <CardTitle className="h4">
                            {props.t(props.title)}
                        </CardTitle>
                        <p className="card-title-desc">
                            {props.t("Click on item to show command content")}
                        </p>
                    </CardHeader>
                    <CardBody>
                        {blobsList.map((oneBlob: any, index: number) => (
                            <Blob
                                key={index}
                                content={oneBlob}
                                type={props.type}
                                reloadParentHandler={reloadParentHandler}
                            />
                        ))}
                    </CardBody>
                </Card>
            </Row>
        </>
    )
}

export default withTranslation()(ChangeLogContent)
