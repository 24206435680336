import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import ChangeLogContent from "../ChangeLogContent"

const ChangeLogDcu = (props: any) => {
    const subTitle = props.t("Pending changes")
    document.title = `${props.t("Pending changes")} | LILA Controller admin`

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={subTitle}
                    breadcrumbItem={props.t("Dimming Curve")}
                />
                <ChangeLogContent
                    t={props.t}
                    type="config"
                    title="List of pending dimming curve changes"
                    apiUrl="/api/pending-dcu"
                />
            </Container>
        </div>
    )
}

export default withTranslation()(ChangeLogDcu)
