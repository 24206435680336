import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"
import useFetch from "react-fetch-hook"
import LoadingErrorHttp from "../../LoadingErrorHttp"
import { Spinner } from "react-awesome-spinners"
import { Row, FormGroup, Label, Input, Form, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import RawPostFormStatus from "./RawPostFormStatus"
import useWindowDimensions from "src/hooks/useWindowDimensions"

import {
    formatDateToLocalTime,
    parseTimeOfDay,
} from "../../../helpers/format_date"

import { createAuthHeader } from "../../../helpers/logged_in_user"
import {
    haspermDisplayDevices,
    haspermManageDevices,
    checkPermissions,
} from "../../../helpers/permission_check"

// 1. zegar astronomiczny (z możliwością dodania czasu korekty +/-30 min.),
// 2. czujnika światła,
// 3. zasilanie zewnętrze (ustawienie i status z konf: komenda: SMOD) aktualnie wszystkie są ustawione na: 3. zasilanie zewnętrze
const deviceModes = (t: any) => {
    return [
        { label: `1. ${t("Astronomical Clock")}`, value: "1" },
        { label: `2. ${t("Light Sensor")}`, value: "2" },
        { label: `3. ${t("External Power Source")}`, value: "3" },
    ]
}

const defaultMode = 3

const LocationDesc = ({ t, location }: any) => {
    return (
        <>
            <label htmlFor="basicpill-address-input" className="form-label">
                {t("Location")}:
            </label>
            &nbsp;
            {location && location.lat != null && location.lon != null ? (
                <span>
                    {location["lat"]}, {location["lon"]}
                </span>
            ) : (
                <span>{t("unknown")}</span>
            )}
        </>
    )
}

const ReportingDesc = ({ t, reporting }: any) => {
    const type = reporting["type"]
    const freq = reporting["freq"]
    const minuteOfDay = reporting["minuteOfDay"]
    if (type === 1) {
        return (
            <>
                {t("Once a day at")}&nbsp;{parseTimeOfDay(minuteOfDay)}
            </>
        )
    }
    if (type === 0) {
        return (
            <>
                {t("Every")}&nbsp;{freq}&nbsp;{t("minutes from")}&nbsp;
                {parseTimeOfDay(minuteOfDay)}
            </>
        )
    }
    return <>{t("No reports set")}</>
}

const Config = ({ deviceId, t }: any) => {
    const [hasPermissionsDisplay, setHasPermissionsDisplay] = useState(false)
    const [hasPermissionsManage, setHasPermissionsManage] = useState(false)

    const abortControllerRef = useRef<AbortController>()

    const [refreshKey, setRefreshKey] = useState<number>(0)

    const [selectedOption, setSelectedOption] = useState<number | null>(null)

    const [sunriseOffset, setSunriseOffset] = useState<number>(0)

    const [sunsetOffset, setSunsetOffset] = useState<number>(0)

    const [submitFlag, setSubmitFlag] = useState<any>(null)

    const [isConfChangeDisabled, setIsConfChangeDisabled] =
        useState<boolean>(true)

    const { width, height } = useWindowDimensions()

    const heightWithoutTable = 320

    const minTableHeight = 160

    const formHeight = Math.max(minTableHeight, height - heightWithoutTable)

    const gatherDataForPost = (): string => {
        return JSON.stringify({
            mode: (selectedOption ?? 0) + 1,
            offset: {
                sunrise: sunriseOffset,
                sunset: sunsetOffset,
            },
        })
    }

    if (deviceId != null) {
        abortControllerRef.current = new AbortController()

        if (!hasPermissionsManage) {
            checkPermissions(haspermManageDevices, setHasPermissionsManage)
        }
        if (!hasPermissionsDisplay) {
            checkPermissions(haspermDisplayDevices, setHasPermissionsDisplay)
        }

        const {
            isLoading,
            data: deviceRawData,
            error,
        } = useFetch<any>(
            `/api/device?data=conf&id=${deviceId}&refreshKey=${refreshKey}`,
            {
                signal: abortControllerRef.current.signal,
                ...createAuthHeader("displayDevices"),
            }
        )

        const {
            data: postResult,
            isLoading: isPosting,
            error: errorPost,
        } = useFetch<any>(`/api/device?data=conf&id=${deviceId}`, {
            method: "POST",
            body: gatherDataForPost(),
            depends: [submitFlag],
            signal: abortControllerRef.current.signal,
            ...createAuthHeader("manageDevices"),
        })

        useEffect(() => {
            setSubmitFlag(null)
            if (deviceRawData) {
                setSelectedOption(deviceRawData["conf"]?.mode - 1)
            }
        }, [postResult, deviceRawData, isPosting, errorPost, refreshKey])

        useEffect(() => {
            return () => {
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort()
                }
            }
        }, [])

        if (isLoading) {
            return <Spinner />
        }

        if (!hasPermissionsDisplay) {
            return <LoadingErrorHttp code="403" permission="displayDevices" />
        }

        if (error) {
            return <LoadingErrorHttp code="500" />
        }

        const deviceData = deviceRawData["conf"]

        const offset = deviceData?.offset ?? null

        const softwareVersion = deviceData?.softwareVersion ?? t("<unknown>")

        const location = deviceData?.location ?? null

        const reporting = deviceData?.reporting ?? {}

        const deviceModesTranslated = deviceModes(t)

        const lastDeviceUpdateTime =
            formatDateToLocalTime(
                deviceRawData?.meta?.conf?.lastDeviceUpdateTime
            ) ?? t("Not set")

        const lastChangeRequestTime =
            formatDateToLocalTime(
                deviceRawData?.meta?.conf?.lastChangeRequestTime
            ) ?? t("Not set")

        const loadMode = () => {
            setRefreshKey((prevKey) => prevKey + 1)
            setIsConfChangeDisabled(true)
        }

        const saveMode = () => {
            //console.log("Save mode data ...")
            setSubmitFlag(true)
        }

        return (
            <Form
                className="needs-validation"
                onSubmit={(e) => {
                    e.preventDefault()
                    return false
                }}
                style={{
                    height: formHeight,
                    maxHeight: formHeight,
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <FormGroup className="mb-3">
                    <Row>
                        <Col lg={6}>
                            <label
                                htmlFor="basicpill-address-input"
                                className="form-label"
                            >
                                {t("DeviceId")}:
                            </label>
                            &nbsp;
                            <span>{deviceId}</span>
                        </Col>
                        <Col lg={6}>
                            <label
                                htmlFor="basicpill-address-input"
                                className="form-label"
                            >
                                {t("Firmware version")}:
                            </label>
                            &nbsp;
                            <span>{softwareVersion}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <LocationDesc t={t} location={location} />
                        </Col>
                        <Col lg={6}>
                            <label
                                htmlFor="basicpill-address-input"
                                className="form-label"
                            >
                                {t("Reporting")}:
                            </label>
                            &nbsp;
                            <ReportingDesc t={t} reporting={reporting} />
                        </Col>
                    </Row>
                </FormGroup>
                <RawPostFormStatus
                    t={t}
                    isError={!!error}
                    isLoading={isLoading}
                    lastDeviceUpdateTimeMsg={lastDeviceUpdateTime}
                    lastChangeRequestTimeMsg={lastChangeRequestTime}
                />
                {isPosting ? (
                    <Spinner />
                ) : (
                    <>
                        <Row>
                            <FormGroup className="mb-3">
                                {!hasPermissionsManage ? null : (
                                    <div className="form-check-right mb-3">
                                        <label
                                            className="form-label"
                                            htmlFor="setConfChanging"
                                        >
                                            {t("Change operation mode")}
                                        </label>
                                        <input
                                            title={t("Change operation mode")}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="setConfChanging"
                                            defaultChecked={
                                                !isConfChangeDisabled
                                            }
                                            onChange={() =>
                                                setIsConfChangeDisabled(
                                                    (state) => !state
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <Select
                                    value={
                                        deviceModesTranslated[selectedOption]
                                    }
                                    onChange={(e: any) => {
                                        setSelectedOption(
                                            parseInt(`${e.value}`) - 1
                                        )
                                    }}
                                    options={deviceModesTranslated}
                                    classNamePrefix="select2-selection"
                                    isDisabled={isConfChangeDisabled}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            {!selectedOption ? (
                                <>
                                    <FormGroup className="mb-3">
                                        <Label className="form-label">
                                            {t("Sunset correction in minutes")}
                                            (+/-)
                                        </Label>
                                        <Input
                                            name="type"
                                            placeholder=""
                                            type="number"
                                            className="form-control"
                                            style={
                                                isConfChangeDisabled
                                                    ? {
                                                          backgroundColor:
                                                              "rgba(230,230,230,0.5)",
                                                      }
                                                    : null
                                            }
                                            defaultValue={offset?.sunset ?? 0}
                                            onChange={(e: any) => {
                                                setSunsetOffset(e.value)
                                            }}
                                            disabled={isConfChangeDisabled}
                                            readOnly={isConfChangeDisabled}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="form-label">
                                            {t("Sunrise correction in minutes")}
                                            (+/-)
                                        </Label>
                                        <Input
                                            name="type"
                                            placeholder=""
                                            type="number"
                                            className="form-control"
                                            style={
                                                isConfChangeDisabled
                                                    ? {
                                                          backgroundColor:
                                                              "rgba(230,230,230,0.5)",
                                                      }
                                                    : null
                                            }
                                            defaultValue={offset?.sunrise ?? 0}
                                            onChange={(e: any) => {
                                                setSunriseOffset(e.value)
                                            }}
                                            disabled={isConfChangeDisabled}
                                            readOnly={isConfChangeDisabled}
                                        />
                                    </FormGroup>
                                </>
                            ) : (
                                <></>
                            )}
                        </Row>
                    </>
                )}
                {hasPermissionsManage && (
                    <Row>
                        <FormGroup className="mb-3">
                            <button
                                onClick={loadMode}
                                type="button"
                                className="btn btn-light me-2"
                            >
                                {t("Reload")}
                            </button>
                            {!isConfChangeDisabled && (
                                <button
                                    onClick={saveMode}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("Submit")}
                                </button>
                            )}
                        </FormGroup>
                    </Row>
                )}
            </Form>
        )
    }
    return <h4 className="card-title">{t("NoDeviceInfo")}</h4>
}

export default withTranslation()(Config)
