import React, { useEffect, useState } from "react"
import { Collapse, Spinner, Alert } from "reactstrap"
import useFetch from "react-fetch-hook"
import LoadingErrorHttp from "../LoadingErrorHttp"
import { createAuthHeader } from "../../helpers/logged_in_user"
import { haspermAdmin, checkPermissions } from "../../helpers/permission_check"
//i18n
import { withTranslation } from "react-i18next"

import classnames from "classnames"

const BlobContent = ({ content, t, reloadParentHandler, type }) => {
    const [refreshPostKey, setRefreshPostKey] = useState<number>(0)
    const [refreshDeleteKey, setRefreshDeleteKey] = useState<number>(0)
    const [opened, setOpened] = useState(false)
    const [hasPermissions, setHasPermissions] = useState(false)
    const [deleteBlob, setDeleteBlob] = useState<boolean>(null)
    const [postBlob, setPostBlob] = useState<boolean>(null)

    const blobName = content?.name ?? ""

    const authAdminHeader = createAuthHeader("admin")

    if (!hasPermissions) {
        checkPermissions(haspermAdmin, setHasPermissions)
    }

    const blobNameParam = encodeURIComponent(blobName)
    const {
        isLoading,
        data: blobContent,
        error,
    } = useFetch<any>(
        `/api/blob?name=${blobNameParam}&type=${type}`,
        authAdminHeader
    )

    const {
        data: deleteResult,
        isLoading: isDeleting,
        error: errorDelete,
    } = useFetch<any>(
        `/api/blob?name=${blobNameParam}&type=${type}&refreshKey=${refreshDeleteKey}`,
        {
            method: "DELETE",
            depends: [deleteBlob],
            ...authAdminHeader,
        }
    )

    const {
        data: postResult,
        isLoading: isPosting,
        error: errorPost,
    } = useFetch<any>(
        `/api/blob?name=${blobNameParam}&type=${type}&refreshKey=${refreshPostKey}`,
        {
            method: "POST",
            body: JSON.stringify(blobContent) ?? "",
            depends: [postBlob],
            ...authAdminHeader,
        }
    )

    const setDeleteEvent = () => {
        setDeleteBlob(true)
        setRefreshDeleteKey((prevKey) => prevKey + 1)
    }

    const setPostEvent = () => {
        setPostBlob(true)
        setRefreshPostKey((prevKey) => prevKey + 1)
    }

    useEffect(() => {
        if (!!deleteResult && !isLoading && !error) {
            reloadParentHandler()
            setDeleteBlob(false)
        }
    }, [deleteBlob, deleteResult, isDeleting, errorDelete])

    useEffect(() => {
        if (!!postResult && !isPosting && !errorPost) {
            //reloadParentHandler() - no refreshing of all commands
            setPostBlob(false)
        }
    }, [postBlob, postResult, isPosting, errorPost])

    if (isLoading || isDeleting || isPosting) return <Spinner />

    if (!hasPermissions) {
        return <LoadingErrorHttp code="403" permission="admin" />
    }

    if (error) return <LoadingErrorHttp code="500" />

    const deviceId = content?.deviceId ?? ""

    const creationDate = content?.creationDate ?? ""

    return (
        <>
            <h2 className="accordion-header" id="headingOne">
                {hasPermissions ? (
                    <button
                        className={classnames("accordion-button", "fw-medium", {
                            collapsed: !opened,
                        })}
                        type="button"
                        onClick={() => {
                            setOpened(!opened)
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <strong>{t("Device")}:</strong>
                        <span>{deviceId}</span>&nbsp;
                        <strong>{t("Change request time")}:</strong>
                        <span>{creationDate}</span>
                    </button>
                ) : null}
            </h2>

            <Collapse isOpen={opened} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-muted">
                        <strong className="text-dark">
                            {JSON.stringify(blobContent) ?? t("No data")}
                        </strong>
                    </div>
                    <hr></hr>
                    {errorDelete ? (
                        <Alert color="danger">
                            {t("Error deleting command for device")}:&nbsp;
                            {deviceId}
                        </Alert>
                    ) : null}
                    {errorPost ? (
                        <Alert color="danger">
                            {t("Error sending command for device")}:&nbsp;
                            {deviceId}
                        </Alert>
                    ) : null}
                    <div className="d-flex flex-wrap gap-2">
                        {blobContent ? (
                            <>
                                <button
                                    type="button"
                                    onClick={setPostEvent}
                                    className="btn btn-success waves-effect btn-label waves-light"
                                >
                                    <i className="bx bx-check-double label-icon"></i>
                                    {t("Send request")}
                                </button>
                                <button
                                    type="button"
                                    onClick={setDeleteEvent}
                                    className="btn btn-danger waves-effect btn-label waves-light"
                                >
                                    <i className="bx bx-trash label-icon"></i>
                                    {t("Delete request")}
                                </button>
                            </>
                        ) : null}
                    </div>
                </div>
            </Collapse>
        </>
    )
}

const Blob = (props: any) => {
    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <BlobContent
                    content={props.content}
                    t={props.t}
                    type={props.type}
                    reloadParentHandler={props.reloadParentHandler}
                />
            </div>
        </div>
    )
}

export default withTranslation()(Blob)
